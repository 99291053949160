import { BBTheme } from 'components/styles/interface';
import Title from 'components/styles/Title';
import useInView from 'hooks/useInView';
import React, { ReactNode } from 'react';
import { Banner as BannerProps } from 'src/types/sanity/schema';
import { isBrowser } from 'utils/booleans';
import { urlFor } from 'utils/sanity/sanity';
import { nextSanityImage } from 'utils/sanity/sanityImage';
import MyImage from '../MyImage';
import BannerStyles from './BannerStyles';

interface Props {
    color?: keyof BBTheme['colors'];
    children?: ReactNode;
    height?: string;
    noOverlay?: boolean;
    bgPosition?: string;
    content: BannerProps;
}
const Banner: React.FC<Props> = ({ color, children, height, noOverlay, bgPosition, content }) => {
    const { image, active, excerpt, heading, tagline } = content;
    const sanityImageUrl = urlFor(image).url();
    // const parallax = React.useRef<HTMLElement>(null);

    // const isMobile = isBrowser ? window.innerWidth < 1025 : true;
    // const [IORef, isVisible] = useInView({ freezeOnceVisible: true, threshold: 0.1, deactivate: isMobile });
    // function scrollHandler() {
    //     const element = IORef ? IORef.current : null;
    //
    //
    //     if (element && isVisible) {
    //
    //         const offset = window.pageYOffset;
    //         element.style.backgroundPositionY = offset * 0.12 - element.offsetHeight * 2 + 'px';
    //     }
    // }
    // React.useEffect(() => {
    //     window.addEventListener('scroll', scrollHandler);
    //     // return the function to remove the handler
    //     // which will be run whn Hero is unmounted
    //     return () => window.removeEventListener('scroll', scrollHandler);
    // }, []);

    return (
        <>
            {active && image && (
                <BannerStyles
                    src={image.asInline ? '' : sanityImageUrl || ''}
                    color={color}
                    height={height}
                    className={`Banner-container mb-l mt-l ${
                        image.asInline && (excerpt || heading || tagline) ? 'borders' : ''
                    }`}
                    bgPosition={bgPosition}
                    // ref={IORef}
                    id={content.slug?.current || ''}
                >
                    {(excerpt || heading || tagline) && !image.asInline && <div className="overlay" />}

                    {heading && <Title as="h2">{heading}</Title>}
                    {(tagline || excerpt) && (
                        <div
                            className={`content-wrapper flex-gap flex-gap-wrap ${
                                image.asInline ? 'space-evenly' : 'center'
                            }`}
                        >
                            <div className={`text ${image.asInline ? 'text-left' : ''}`}>
                                {tagline && (
                                    <p className={`featured ${image.asInline ? 'constrain-width' : ''}`}>{tagline}</p>
                                )}
                                {excerpt && <p className="">{excerpt}</p>}
                            </div>
                            {image?.asInline && (
                                <MyImage
                                    loader="sanity"
                                    nextImageProps={{
                                        ...nextSanityImage(image),
                                        width: 150,
                                        height: 150,
                                        alt: heading,
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {children}
                </BannerStyles>
            )}
        </>
    );
};

export default Banner;
