import { ElementLink } from 'components/Layout/Link';
import Title from 'components/styles/Title';
import React from 'react';
import { Article } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { urlFor } from 'utils/sanity/sanity';
import { ArticleFeaturedImg } from './JournalStyles';
const RelatedArticleStyles = styled.aside`
    /* display: flex;
    flex-direction: column;
    height: min-content; */
`;
interface RelatedArticleProps {
    article: Article;
    className?: string;
}

const RelatedArticle: React.FC<RelatedArticleProps> = ({ article, className }) => {
    const { title, mainImage, body } = article;
    const featuredImage = urlFor(mainImage).url();
    return (
        <RelatedArticleStyles className="RelatedArticle-container">
            <p className="aside">Related Articles</p>
            <ElementLink text="" hrefProp={`/journal/${article.slug}`} className={``}>
                {featuredImage && (
                    <ArticleFeaturedImg
                        // data-articleid={article._id || ''}
                        src={featuredImage}
                        alt={title}
                        // srcSet={article.featuredImage?.srcSet || ''}
                        className={`image related ${className || ''}`}
                    />
                )}
            </ElementLink>
            {/* <span className="heading-6 alt">Flowers Journal</span> */}
            <Title as="h5" className="">
                {title}
            </Title>
        </RelatedArticleStyles>
    );
};

export default RelatedArticle;
