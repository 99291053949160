import { Column } from 'components/Layout/grid/Column';
import { Row } from 'components/Layout/grid/Row';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { Q_ALL_COLLECTIONS } from 'src/queries/sanity';
import styled from 'styled-components';
import useSWR from 'swr';
import { getClient } from 'utils/sanity/sanity';
import { CartContext } from '../../../state/context/global';
import { capitalize, pluralizeNoun } from '../../../utils/stringFunctions';
import { MyLink } from '../../Layout/Link';
import MyImage from '../../Layout/MyImage';
import { device } from '../../styles/mixins';
import { bbTheme } from '../../styles/theme';
import { CAContent, CAHeader } from '../../templates/ContentTemplate';
import { EmptyProductsListStyles } from '../CartProduct/List';

const SocialWidgetsNoSSR = dynamic(() => import('../../Layout/social/SocialWidgets'), { ssr: false });

// export interface ProductsCollectionProps {
//     tag?: 'Wedding' | 'Gift Cards' | 'Online Course' | 'Spring Fleursage' | 'Spring Fleursage' | 'Christmas';
//     title?: 'Weddings' | 'Popular' | 'Spring ‘Fleursage’' | 'Collection Only' | 'Christmas' | 'Gift of Life';
//     collections?: ExtendedSanityShopifyCollection[];
// }

const ProductCollectionStyles = styled.section`
    .row-wrapper {
        justify-content: center;
    }

    .column-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-height: 40rem;
        max-height: 90vh;
    }

    /* TODO Extract into Row */
    .collection:nth-child(even) {
        .row-wrapper {
            justify-content: space-around;
        }
        .column-1 {
            margin-left: 0 !important;
        }

        .column-2 {
            order: -1;
            align-items: flex-end;
            text-align: right;
            @media ${device().medium_small} {
                order: 1;
                padding-left: ${({ theme }) => theme.spacing.m};
            }
        }
    }
`;

const Collection: React.FC<any> = ({ tag, title, collections }) => {
    // const { data, loading } = useQuery<collections, collectionsVariables>(COLLECTIONS, {
    //     variables: { preferredContentType: ImageContentType.JPG, title: title || '' },
    //     skip: !!collections,
    //     ssr: true,
    // });

    const { data, error } = useSWR(Q_ALL_COLLECTIONS, (query) => getClient().fetch(query), {
        initialData: collections,
    });
    // const router = useRouter();
    const cart = useContext(CartContext);
    const currentCollections = collections || data;

    // function openPdp(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>, handle: string) {
    //     e.stopPropagation();
    //     e.target == e.currentTarget ? router.replace(`/shop/products/${handle}`) : null;
    // }

    if (!currentCollections || !cart) {
        return <div>Loading products...</div>;
    }

    if (!currentCollections?.length)
        return (
            <EmptyProductsListStyles>
                <p>
                    There Doesn&apos;t seem to be any{' '}
                    {tag ? pluralizeNoun(capitalize(tag)) : 'collections of that type'} available. Check back soon!{' '}
                </p>
                <MyLink text="View our Store" hrefProp="/shop" />
            </EmptyProductsListStyles>
        );

    return (
        <ProductCollectionStyles>
            {currentCollections.length > 0 &&
                currentCollections.map((collection, i, arr) => {
                    const { medium_small, large } = bbTheme.breakpoints;
                    // logProductIndexImpression() // TODO Needs variant for collections
                    return (
                        <React.Fragment key={collection.shopifyId}>
                            {collection.title && collection?.products?.length > 0 && (
                                <div className="collection" style={{ margin: title ? 'auto' : '5rem 0' }}>
                                    {/* {collection.node.products.edges[i] && collection.node.products.edges[i].node.availableForSale && ( */}
                                    <div aria-hidden={true} tabIndex={-1} className="anchor" id={collection.handle} />

                                    <Row reverse={i === 2} breakpoints={[medium_small, large]}>
                                        <Column widths={[6, 4]}>
                                            <CAHeader>{collection.title}</CAHeader>
                                            <CAContent>{collection.store.description}</CAContent>

                                            <MyLink hrefProp={`/collections/${collection.handle}`} text={'Shop Now'} />
                                        </Column>

                                        <Column widths={[6, 4]} offsets={[0, 2]}>
                                            {collection.store.image?.w800 && (
                                                <MyImage
                                                    loader="shopify"
                                                    nextImageProps={{
                                                        src: collection.store.image?.w800,
                                                        layout: 'fill',
                                                        objectFit: 'cover',
                                                        alt: collection.title,
                                                        // width: SHOPIFY_COLLECTION_BANNER_WIDTH,
                                                        // height: SHOPIFY_COLLECTION_BANNER_HEIGHT,
                                                    }}
                                                />
                                            )}
                                        </Column>
                                    </Row>
                                    {/* {i < arr.length - 1 && <Divider size="m" />} */}
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
        </ProductCollectionStyles>
    );
};

export default Collection;
