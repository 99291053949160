import styled from 'styled-components';
import { banner, device } from '../../styles/mixins';
import { bouquetLeftImg } from '../../../images';
import { modifyAlpha } from 'utils/styleFunctions';
import { BBTheme } from 'components/styles/interface';

interface BannerProps {
    src?: string;
    color?: keyof BBTheme['colors'];
    height?: string;
    bgPosition?: string;
}

const BannerStyles = styled.section<BannerProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    min-height: ${({ height }) => height || '40rem'};
    background: ${({ color }) => color} url(${({ src }) => src}) no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    perspective: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    /* transition: ${({ theme }) => theme.transitions.base}; */
    color: ${({ theme, color }) => (color ? theme[color] : theme.colors.white)};
    & * {
        color: ${({ theme, src }) => (src ? theme.colors.white : theme.colors.black)};
        text-shadow: 0px 0px 10px ${({ theme, src }) => (src ? modifyAlpha(theme.colors.black, 0.2) : 'none')};
    }

    .content-wrapper {
        padding: 0 ${({ theme }) => theme.spacing.gutter};
        position: relative;
        align-items: center;
        max-width: 100rem;
    }
    .MyImage-container {
        width: auto;
        height: auto;
    }
    .text {
        @media ${device(true).small} {
            text-align: center;
        }
        max-width: ${({ theme }) => theme.typography.maxPWidth};
        .constrain-width {
            max-width: 46rem;
        }
        p {
            font-weight: 400;
            font-family: ${({ theme }) => theme.typography.font_family.heading};
        }
    }
    @media ${device().small} {
        background-position: ${({ bgPosition }) => bgPosition || 'center'};
        background-size: cover;
    }

    @media (hover: none) {
        background-attachment: initial;
    }

    ${banner}
    z-index: 1;

    &.corner-text {
        @media ${device().medium_large} {
            padding: ${({ theme }) => theme.spacing.xl};
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.4;
        background: ${({ src, theme }) =>
            src ? 'linear-gradient(0deg, #000008 30%, #fff 100%)' : theme.colors.gradients.muted_honey};
        pointer-events: none;
        z-index: -1;
    }

    &.borders {
        border-top: ${({ theme }) => theme.borders.base};
        border-bottom: ${({ theme }) => theme.borders.base};
    }
`;

export default BannerStyles;
