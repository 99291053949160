import styled from 'styled-components';
import { banner } from '../../../styles/mixins';

const SectionLinks = styled.section<{ image?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50rem;
    position: relative;
    background: url(${({ theme, image }) =>
            image ||
            'https://res.cloudinary.com/dwa5hefth/image/upload/v1593217686/BumblebeeflowerFarm-Static/Eddie-Attwell-min.jpg'})
        no-repeat center;
    background-size: cover;
    z-index: 1;
    padding: ${({ theme }) => theme.spacing.gutter};

    .latest-list {
        margin: auto;
        min-height: 10rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        @media only screen and (max-width: 900px) {
            flex-flow: row wrap;
        }
        @media only screen and (max-width: 550px) {
            flex-flow: column nowrap;
            padding: 5rem 0;
        }
    }
    .list-item {
        width: 20rem;
        padding: 0 2rem;
        position: relative;
        transition: opacity 200ms ease-in-out;
        &:hover {
            opacity: 0.7;
        }
        @media only screen and (max-width: 550px) {
            width: 100%;
            padding: 0;
        }

        @media only screen and (max-width: 900px) {
            flex: 1 1 50%;
        }
        &:first-child ::before {
            content: none;
        }
        &::before {
            content: '';
            height: 12rem;
            width: 0.5px;
            position: absolute;
            left: 0;
            top: -1.5rem;
            background-color: ${({ theme }) => theme.colors.white};
            display: inline-block;

            @media only screen and (max-width: 900px) {
                display: none;
            }
        }
    }

    .list-title,
    .list-content {
        color: ${({ theme }) => theme.colors.white};
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.7;
        background: ${({ theme }) => theme.colors._grey[800]};
        pointer-events: none;
        z-index: -1;
    }
`;

export default SectionLinks;
