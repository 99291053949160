import React from 'react';
import { CallToAction } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { devLog } from 'utils/logs';
import { MyLink } from '../Link';

const AnnouncementStyle = styled.section`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    /* h2 {
        font-size: 4rem;
        margin: 3rem;
        @media only screen and (max-width: 900px) {
            font-size: 2rem;
        }
    } */
`;

interface Props {
    content: CallToAction;
}
// TODO Expand / Co
type internalLinkType = 'page' | 'article' | 'shopifyProduct';
const Announcement: React.FC<Props> = ({ content }) => {
    const { internalLink, linkText, url, file, title, linkType, linkHash } = content;

    const fileUrl = content['fileUrl'];
    const slug = content['handle'] || content['slug'];

    const internalLinkType: internalLinkType = content['internalLinkType'];

    function handleCurrentLink() {
        switch (linkType) {
            case 'file':
                return fileUrl;
            case 'internalLink':
                if (internalLinkType === 'article') {
                    return `journal/${slug}`;
                }
                if (internalLinkType === 'shopifyProduct') {
                    return `shop/products/${slug}`;
                }
                if (internalLinkType === 'page') {
                    return `${`${slug}`?.replace(/home/i, '/') || ''}${linkHash ? '#' + linkHash : ''}`;
                }
            case 'url':
                return `${url}${linkHash ? '#' + linkHash : ''}`;
            default:
                console.error('No Link for CTA found');
                return '';
        }
    }
    const isExternalLink = /http(s)?/.test(handleCurrentLink());

    return (
        // <BannerStyles
        //     src={''}
        //     height={'30rem'}
        //     className="Banner-container mb-xl mt-xl"
        //     color="backgrounds_muted_honey"
        // >
        <AnnouncementStyle id={content.slug?.current || ''}>
            {title && <h2>{title}</h2>}
            {(slug || fileUrl) && (
                <MyLink
                    scroll={true}
                    hrefProp={handleCurrentLink()}
                    text={linkText}
                    target={isExternalLink ? '_blank' : '_self'}
                    eventLabel={linkText}
                    external={isExternalLink}
                />
            )}
        </AnnouncementStyle>
        // </BannerStyles>
    );
};

export default Announcement;
