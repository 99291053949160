import SocialWidgets from 'components/Layout/social/SocialWidgets';
import Title from 'components/styles/Title';
import dayjs from 'dayjs';
import { handleNestedSlugs } from 'pages/[slug]';
import React from 'react';
import { Article } from 'src/types/sanity/schema';
import { handleSanitySlug, urlFor } from 'utils/sanity/sanity';
import { decodeHTML, truncate } from 'utils/stringFunctions';
import parseHtml from '../../utils/parseHtml';
import ThreeDotsWave from '../animations/loading/ThreeDotsLoader';
import { ElementLink, MyLink } from '../Layout/Link';
import { ArticleFeaturedImg, ArticleMetaStyles } from './JournalStyles';
import RelatedArticle from './RelatedArticle';
export interface ArticleMetaProps {
    handleClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
    article: Article;
    relatedArticle?: Article;
    className?: string;
    idx?: number;
    excerpt?: boolean;
    isSingle?: boolean;
    onIndex?: boolean;
    asRelated?: boolean;
    imageBanner?: boolean;
}

export function journalExcerpt(excerpt, idx) {
    return parseHtml(excerpt.split(' ').slice(0, 18).join(' ') + '...');
}

export function journalDate(dateStr: string) {
    // const d = new Date(dateStr);
    const d = dayjs(dateStr);

    return d.format('MMMM, YYYY');
}

export function journalTitle(title: string) {
    const decodedTitle = decodeHTML(title);
    const matches = decodedTitle.match(/\!+|\.+|\?+/);
    const separator = matches?.length ? matches[0] : '';
    switch (true) {
        case /\.+/.test(decodedTitle):
        case /\?+/.test(decodedTitle):
        case /\!+/.test(decodedTitle):
            return decodedTitle.split(separator)[0];
        default:
            // return parseHtml(decodedTitle);
            return decodedTitle;
    }
}

const ArticleMeta: React.FC<ArticleMetaProps> = ({
    article,
    handleClick,
    className,
    idx,
    excerpt,
    isSingle,
    onIndex,
    asRelated,
    relatedArticle,
    imageBanner,
}) => {
    if (!article) return <ThreeDotsWave />;
    const cats = article?.categories
        ? article.categories
              .map((c) => c['title'])
              .filter((el) => el?.toLocaleLowerCase() !== 'journal' && el?.toLocaleLowerCase() !== 'featured')
              .join(', ')
        : '';
    const { title, mainImage, body } = article;
    const featuredImage = urlFor(mainImage).url();
    return (
        <ArticleMetaStyles className={`${className || ''}`} imageBanner={imageBanner}>
            <ElementLink
                tabIndex={-1}
                text=""
                hrefProp={`/journal/${typeof article.slug === 'object' ? article.slug.current : article.slug}`}
                passThrough={isSingle}
                className={`i${!onIndex ? idx : ''}`}
            >
                {featuredImage && (
                    <ArticleFeaturedImg
                        // data-articleid={article._id}
                        src={featuredImage}
                        alt={article.title}
                        // srcSet={article.featuredImage?.srcSet || ''}
                        className={`image ${className || ''} ${onIndex ? 'i' + idx : ''} ${
                            imageBanner ? 'asBanner' : ''
                        }`}
                    />
                )}
            </ElementLink>

            {relatedArticle && <RelatedArticle article={relatedArticle} />}

            {isSingle && (
                <SocialWidgets iconBorder type="vertical" article={article} text="Dig this article? SHARE THE LOVE" />
            )}

            {asRelated ? null : (
                <p className="date dark">
                    <span className="">{article.publishedAt && journalDate(article.publishedAt)}</span>
                </p>
            )}
            {isSingle && (
                <>
                    <MyLink
                        text="Back"
                        hrefProp="/journal"
                        btnStyle="clear"
                        back
                        className="backlink top"
                        icon={{ name: 'arrow', title: 'Back to Index' }}
                    />
                    {/* <MyLink
                        text="Back"
                        hrefProp="/journal"
                        btnStyle="clear"
                        back
                        className="backlink bottom"
                        icon={{ name: 'arrow', title: 'Back to Index' }}
                    /> */}
                </>
            )}
            <div className="title">
                <Title
                    as={isSingle ? 'h1' : 'h5'}
                    // data-articleid={article._id}
                    className={` ${onIndex ? 'heading-4' : asRelated ? 'heading-5' : ''}`}
                >
                    <ElementLink text="" hrefProp={`/journal/${handleSanitySlug(article.slug)}`} passThrough={isSingle}>
                        {journalTitle(article.title || '')}
                    </ElementLink>
                </Title>
                {asRelated ? null : (
                    <span className="author paragraph italics dark">Written by {article?.author?.['name']}</span>
                )}
            </div>

            {/* <MyLink text="Read More" hrefProp={`/journal/${article.slug.current}`}  /> */}

            {excerpt && article.excerpt && <p className="excerpt">{journalExcerpt(article.excerpt, idx)}</p>}

            <span className="caption-text categories">
                Posted under{' '}
                {cats.split(',').map(
                    (cat, idx) =>
                        idx < 2 && (
                            <span key={cat} className="caption-text cat">
                                {cat.toUpperCase()}
                            </span>
                        ),
                )}
            </span>
        </ArticleMetaStyles>
    );
};

export default ArticleMeta;
