import styled from 'styled-components';
import { device } from '../styles/mixins';
import { RelatedStoriesStyles } from './RelatedStories';

export const ArticleIndexStyles = styled.section`
    h1 {
        text-align: center;
        margin: 0 0 ${({ theme }) => theme.spacing.xl} 0;
    }
    overflow: hidden;
    .articles {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.s};

        @media ${device().medium_small} {
            grid-gap: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.m};
            grid-template-columns: minmax(230px, 1fr) minmax(230px, 1fr);
            grid-auto-flow: dense;
        }

        @media ${device().large} {
            grid-template-columns: minmax(230px, 1fr) minmax(240px, 1.2fr) minmax(230px, 1fr);
            grid-gap: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.xxl};
        }
    }
`;

export const JournalPageStyles = styled.section`
    position: relative;
    border-bottom: ${({ theme }) => theme.borders.base};
`;

export const ArticleContentStyles = styled.article`
    position: relative;
    z-index: 10;
    padding: 0 ${({ theme }) => theme.spacing.s};

    & > div > * {
        padding: ${({ theme }) => theme.spacing.xs} 0;
    }

    @media ${device().small} {
        & > div > * {
            padding: ${({ theme }) => theme.spacing.xs} 0;
        }
    }

    .aligncenter {
        margin: 0 auto;
    }

    .wp-caption-text {
        font-style: italic;
        font-size: 80%;
    }

    .wp-caption {
        width: 100%;
        margin: 4rem auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
`;

interface ArticleProps {
    imageBanner?: boolean;
}

export const ArticleMetaStyles = styled.div<ArticleProps>`
    /* As Article Card */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 0.7rem;
    height: fit-content;

    p {
        margin: 0;
        max-width: 85%;
    }

    .cat {
        padding: 0.3rem 0.5rem;
        margin-left: 0.5rem;
        border: ${({ theme }) => theme.borders.base};
        border-color: ${({ theme }) => theme.colors.black};
    }

    .social-widgets,
    .RelatedArticle-container {
        display: none;
    }

    /* styles on a journal page */
    ${JournalPageStyles} > & {
        .backlink {
            --btn-min-width: 3rem;
            position: absolute;
            border: none;
            &.top {
                top: 6rem;
                left: 2vh;
            }
            &.bottom {
                bottom: 0;
                left: 2vh;
            }
        }

        grid-template-columns: 1fr min(32ch, 40%) min(32ch, 40%) 1fr;

        grid-gap: ${({ theme }) => theme.spacing.xs};
        .image {
            grid-column: 1 / -1;
            grid-row: 1 / 2;
        }

        .date {
            grid-column: 3 / -1;
            grid-row: 2 / 3;
            justify-self: center;
        }

        .categories {
            grid-column: 2 / 4;
            grid-row: 2 / 3;
        }

        .title {
            grid-column: 2 / -1;
        }

        .author {
            grid-column: 2 / -1;
        }

        @media ${device().medium_small} {
            overflow: hidden;
            margin-bottom: 5rem;
            grid-column-gap: ${({ theme }) => theme.spacing.s};
            .image {
                height: 120%;

                grid-column: 1 / 3;
                grid-row: 1 / 9;
            }

            .date {
                grid-column: 4 / 5;
                grid-row: 2 / 3;
            }

            .categories {
                grid-column: 3 / -1;
                grid-row: 2 / 3;
            }

            .title {
                grid-column: 3 / -1;
                grid-row: 4 / 5;
            }

            .author {
                grid-column: 3 / -1;
                grid-row: 6 / 7;
            }
        }

        @media ${device().large} {
            grid-template-rows: ${({ imageBanner }) =>
                imageBanner
                    ? '60vh repeat(5, minmax(40px, 64px))'
                    : '1rem 10rem repeat(6, minmax(min-content, max-content))'};
            grid-template-columns: repeat(12, 1fr);
            overflow: visible;

            margin: ${({ imageBanner }) => (imageBanner ? '-6rem 0 0 0' : '')};
            .image {
                height: 120%;

                grid-column: 5 / 9;
                grid-row: 5 / -1;

                &.asBanner {
                    grid-column: 1 / -1;
                    grid-row: 1 / 2;
                    width: 120%;
                    margin: 0 -3.9vw;
                    height: 60vh;
                }
            }

            .RelatedArticle-container {
                display: block;
                grid-column: 10 / 12;
                grid-row: 3 / 6;
                max-height: 25rem;

                & .image {
                    height: 100%;
                }
            }

            .date {
                grid-column: 10 / -1;
                grid-row: 2 / 3;
            }

            .categories {
                grid-column: 5 / 9;
                grid-row: 2 / 3;
            }

            .title {
                grid-column: 5 / 9;
                grid-row: 3 / 4;
            }

            .author {
                grid-column: 5 / 9;
                grid-row: ${({ imageBanner }) => (imageBanner ? ' 6 / 6' : ' 4 / 4')};
                align-self: ${({ imageBanner }) => (imageBanner ? 'baseline' : 'end')};
            }

            .backlink.top {
                position: static;
                grid-column: 2 / 4;
                grid-row: 2 / 3;
                justify-self: baseline;
            }
            .categories {
                padding: 0.4rem 0;
            }

            .social-widgets {
                a {
                    padding: ${({ theme }) => theme.spacing.xs};
                }
                display: inline-flex;
                grid-column: 2 / 4;
                grid-row: 3 / 6;
                --social-widgets-margin: 0.3rem 0.3rem 0.3rem 0;
                --svg-font-size: 1.5rem;
            }

            & > *:not(img) {
                padding: 0;
            }
        }
    }

    ${RelatedStoriesStyles} & {
        /* max-width: calc(100vw - 7.8vw); */

        .image {
            height: 40vh;
        }
    }
`;

export const ArticleFeaturedImg = styled.img`
    width: 100%;
    object-fit: cover;
    height: 50vh;
    max-height: 600px;
    ${ArticleMetaStyles}:nth-child(3n-1) & {
        @media ${device().large} {
            height: 60vh;
        }
    }
`;
