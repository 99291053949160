import Title from 'components/styles/Title';
import { cascadeImg, defaultImage, headDressMagda, herbGardenImg, lateSummerCascade } from 'images';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Gallery } from 'src/types/sanity/schema';
import { urlFor } from 'utils/sanity/sanity';
import { MyLink } from '../Link';
import { MasonryWrapper } from './MasonryGalleryStyles';

export const defaultImageGalleryImages = [cascadeImg, defaultImage, headDressMagda, herbGardenImg, lateSummerCascade];
export type ImageSrc = { src: string; filename: string; thumbnail: string };

export interface MasonryImagesProps {
    gallery: Gallery;
}

const MasonryGallery: React.FC<MasonryImagesProps> = ({ gallery }) => {
    const nextImages = gallery?.images?.map((img) => {
        const imgCreditInstagram = img['creditInstagram'];
        const imgCreditName = img['creditName'];
        const imgCreditUrl = img['creditUrl'];
        return {
            original: urlFor(img).url(),
            thumbnail: urlFor(img).maxHeight(150).maxWidth(150).url(),
            // originalTitle: 'Image Title',
            // thumbnailLabel: 'Image Label',
            description:
                imgCreditInstagram || imgCreditName || imgCreditUrl
                    ? `Photographer: ${'@' + imgCreditInstagram || imgCreditName || imgCreditUrl}`
                    : null,
        };
    });

    // const nextImageSrcs = nextImages?.map((el) => el.src);
    const galleryCreditInstagram = gallery['creditInstagram'];
    const galleryCreditName = gallery['creditName'];
    const galleryCreditUrl = gallery['creditUrl'];

    return (
        <MasonryWrapper id={gallery.slug?.current || 'gallery'} className="Masonry-container">
            {gallery.title && (
                <Title as="h3" className="text-center">
                    {gallery.title}
                </Title>
            )}
            {(galleryCreditInstagram || galleryCreditName || galleryCreditUrl) && (
                <div className="text-center">
                    {galleryCreditName && (
                        <Title as="h5" className="text-center">
                            {galleryCreditName}
                        </Title>
                    )}
                    {galleryCreditInstagram && (
                        <MyLink
                            className="photo-credits-ig"
                            hrefProp={`${'https://www.instagram.com/' + galleryCreditInstagram}`}
                            text={`IG: @${galleryCreditInstagram}`}
                            btnStyle="clear"
                            icon={{ name: 'none' }}
                            external
                            target="_blank"
                        />
                    )}
                    {galleryCreditUrl && !/instagram\.com/.test(galleryCreditUrl) && (
                        <MyLink
                            className="photo-credits-web"
                            hrefProp={`${galleryCreditUrl ? galleryCreditUrl : ''}`}
                            text={'Website'}
                            btnStyle="clear"
                            icon={{ name: 'none' }}
                            external
                            target="_blank"
                        />
                    )}
                </div>
            )}

            <ImageGallery additionalClass="Gallery" items={nextImages} />
        </MasonryWrapper>
    );
};

export default MasonryGallery;
