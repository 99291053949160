import React from 'react';
import styled from 'styled-components';
import { clampN } from 'utils/numberFunctions';

const SlidePaginationStyles = styled.div`
    .current {
        height: var(--divider-line-height, 10rem);
        width: auto;
        border-left: 1px solid currentColor;
        transform: translate(3px, 0);
        margin: ${({ theme }) => theme.spacing.s} 0;
    }
`;

export interface PaginationProps {
    pages: { title: string }[];
    currentPage: number;
}

const SlidePagination: React.FC<PaginationProps> = ({ pages, currentPage }) => {
    const clampedNum = clampN(currentPage, 2, pages.length - 1);
    return (
        <SlidePaginationStyles className="SlidePagination-container hide-for-small">
            <span className="previous">{clampedNum - 1}</span>
            <div className="current"></div>
            <span className="next">{clampedNum + 1}</span>
        </SlidePaginationStyles>
    );
};

export default SlidePagination;
