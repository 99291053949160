import React from 'react';
import styled from 'styled-components';
import { banner } from '../../../styles/mixins';
import Link from 'next/link';
import SectionLinksStyles from './Style';
import { SectionLinks as SectionLinksProps } from 'src/types/sanity/schema';
import { urlFor } from 'utils/sanity/sanity';
import { devLog } from 'utils/logs';
import { buildSanityLink } from 'utils/sanity/sanity';

export type SanityInternalLinkType = 'article' | 'shopifyProduct' | 'page';

const SectionLinks: React.FC<SectionLinksProps> = ({ backgroundImage, links, title }) => {
    return (
        <SectionLinksStyles image={urlFor(backgroundImage).url() || ''} className="SectionLinks-container full-bleed">
            <div className="overlay" />
            <ul className="latest-list">
                {links?.map((link) => {
                    const linkType = link['linkType'];
                    const slug = link['slug'];
                    return (
                        <li key={link.tagline + link.title} className="list-item">
                            {slug && (
                                <Link href={'/' + buildSanityLink(linkType, slug)} passHref>
                                    <a href="#0" className="list-link">
                                        <h5 className="list-title">{link.title}</h5>
                                        <p className="list-content">{link.tagline}</p>
                                    </a>
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ul>
        </SectionLinksStyles>
    );
};

export default SectionLinks;
