import Collections from 'components/shop/Product/Collections';

import React from 'react';

import { CollectionSection } from 'src/types/sanity/schema';
import { PortableText } from 'utils/sanity/sanity';

const CollectionsSection: React.FC<CollectionSection> = ({ _type, active, body, collections, title }) => {
    // TODO Type Queries
    const currentCollections = collections as unknown;

    return (
        <section id={'collectionSection'}>
            {body && <PortableText blocks={body} />}
            {currentCollections ? <Collections collections={currentCollections} /> : null}
        </section>
    );
};

export default CollectionsSection;
