import * as Yup from 'yup';
import { eircodeRe, northernIre } from '../../utils/regexes';

export const emailSchema = Yup.object().shape({
    email: Yup.string().email('is invalid').required('is required'),
});

export const userSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'is too short!').max(50, 'is too long!').required('is required'),
    lastName: Yup.string().min(2, 'is too short!').max(50, 'is too long!').required('is required'),
    address1: Yup.string().min(5, 'is too short').required('is required'),
    address2: Yup.string().trim(),
    city: Yup.string().required('is required'),
    province: Yup.string().required('is required'),
    // .when('zip', {
    //     is: zip => typeof zip === 'string' && zip.length > 5,
    //     then: Yup.string().required('County is required'),
    //     otherwise: Yup.string(),
    // }),
    // .when('country',{
    //     is: (country)=> isInCountry("ni",country),
    //     then:
    // })
    // country: Yup.string().required('is required'),
    // zip: Yup.string()
    //     .matches(eircodeRe, 'Incorrect format')
    //     .required('Eircode is required'),
    zip: Yup.string().when('country', {
        is: 'United Kingdom',
        then: Yup.string().matches(northernIre, 'is in an incorrect format').required('is required'),
        otherwise: Yup.string().matches(eircodeRe, 'is in an incorrect format').required('is required'),
    }),
    phone: Yup.string().min(7, 'is too short').required('is required'),
    // gardenerType: Yup.string().required('is required'),
});

export const contactSchema = Yup.object().shape({
    name: Yup.string().min(2, 'is too short!').max(50, 'is too long!').required('is required'),
    email: Yup.string().email('is invalid').required('is required'),
    subject: Yup.string().required('is required'),
    message: Yup.string().required('is required'),
});
