import { sectionPadding, banner } from '../styles/mixins';
import styled from 'styled-components';

const ContactFormStyles = styled.section`
    min-height: 33rem;
    position: relative;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    @media only screen and (max-width: 550px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    &.hidden {
        min-height: auto;
    }

    .hidden-form {
        ${sectionPadding}
        display: grid;
        place-items: center;
        width: 100%;
    }

    .form-wrapper {
        position: relative;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 768px) {
            flex-flow: column nowrap;
        }

        button[type='submit'] {
            margin-top: 1rem;
        }
        .reset-button {
        }
        .form-container {
            flex: 1 0 55%;
            @media only screen and (max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }

            .success-state,
            .error-state,
            form {
                width: 100%;
                max-width: 50rem;
                margin: 0 auto;
                @media only screen and (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                }
                .name,
                .email {
                    @media only screen and (min-width: 450px) {
                        max-width: 45%;
                        display: inline-block;
                    }
                }

                .email {
                    float: right;
                }
            }

            .success-state,
            .error-state {
                margin-top: 5rem;
                text-align: center;
                height: 100%;
                background-color: ${({ theme }) => theme.colors.white};
                span {
                    display: block;
                    margin: 1rem 0;
                }
            }

            .form-field {
                flex: 1 1 100%;
                width: 100%;
                max-width: 100%;
            }
            .motion-form-wrapper {
                width: 100%;
            }
        }
    }

    .error {
        position: relative;
    }

    .contact-details {
        flex: 1 0 35%;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            width: 100%;
            flex-flow: row wrap;
        }

        address {
            margin: 0 2rem 2rem 0;
        }

        .hidden-details {
            display: inline-block;
            min-width: 250px;
        }
        button {
            display: block;
            min-width: 13rem;
        }
    }

    hr {
        width: 75%;
        height: 0.5px;
        margin: 5rem auto 3rem auto;
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
`;

export default ContactFormStyles;
