import React from 'react';
import styled from 'styled-components';
import { CAHeader } from '../../templates/ContentTemplate';
import { sectionPadding } from '../../styles/mixins';
import { TestimonialSlider } from 'src/types/sanity/schema';
import MyImage from '../MyImage';
import { nextSanityImage } from 'utils/sanity/sanityImage';
import Title from 'components/styles/Title';
import { MyLink } from '../Link';

const TestimonialStyles = styled.section`
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    text-align: center;

    .title {
        margin: 0 auto 3rem auto;
    }

    .feedback-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .feedback-item {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-evenly;
            align-items: center;
            width: 33%;
            max-width: 300px;

            .image {
                position: relative;
                width: 110px;
                height: 110px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 200px;
                    position: absolute;
                }
            }

            .content {
            }
            .details {
                margin: 1rem 0 0 0;
                .name {
                    padding: ${({ theme }) => theme.spacing.xs};
                }
            }
            .work {
            }
        }

        .fi-1 {
            .image {
                img {
                    left: -60px;
                    top: 0px;
                }
            }
        }
        .fi-2 {
            .image {
                img {
                    width: 200px;
                    left: -53px;
                    top: 1px;
                }
            }
        }
        .fi-3 {
            .image {
                img {
                    width: auto;
                    top: -20px;
                    left: 0px;
                    height: 200px;
                }
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        .feedback-container {
            flex-flow: column nowrap;

            .feedback-item {
                flex-flow: column nowrap;
                padding: 2rem 1rem;
                width: 400px;
                max-width: none;

                .content {
                    width: 100%;
                }
                .details {
                    margin: 1rem 0 0 0;
                    .name {
                        font-family: ${({ theme }) => theme.typography.font_family.heading};
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .feedback-container {
            .feedback-item {
                flex-flow: column nowrap;
                padding: 2rem 1rem;
                width: 100%;
                max-width: none;
            }
        }
    }
`;

const Testimonial: React.FC<TestimonialSlider> = ({ slider, title, tagline }) => {
    return (
        <TestimonialStyles id="kind-words">
            {title && (
                <Title as="h4" className="title">
                    {title}
                </Title>
            )}
            {tagline && <p className="featured tagline">{tagline}</p>}
            <div className="feedback-container">
                {slider &&
                    slider?.map((slide, idx) => (
                        <div key={slide.author} className={`feedback-item fi-${idx + 1}`}>
                            <div className="image">
                                {slide.image && (
                                    <MyImage
                                        loader="sanity"
                                        nextImageProps={{
                                            ...nextSanityImage(slide.image),
                                            width: undefined,
                                            height: undefined,
                                            layout: 'fill',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                            </div>
                            <p className="content">{slide.content}</p>
                            <p className="details">
                                {slide.link && (
                                    <MyLink hrefProp={slide.link} external target="_blank" className="name">
                                        {slide.author}
                                    </MyLink>
                                )}
                                <br />
                                {slide.position && <span className="work">{slide.position}</span>}
                            </p>
                        </div>
                    ))}
            </div>
        </TestimonialStyles>
    );
};

export default Testimonial;
