import { Field, Form, Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { contactEmail } from '../../core/constants';
import { dfContactForm } from '../../core/defaults';
import { ContactResponseData } from '../../pages/api/contact';
import copyMessage from '../../utils/copyToClipBoard';
import ThreeDotsWave from '../animations/loading/ThreeDotsLoader';
import Button from '../Layout/Buttons';
import { contactSchema } from '../validation/schemas';
import { FormField, MyError, MyInput, MyLabel, MyTextArea } from './FormElements';
import postContactData from './postContactData';
const ContactForm: React.FC = () => {
    const floristTypes = ['hobbyist', 'professional', 'natureLover', 'other'];
    const [messageStatus, setMessageStatus] = useState<ContactResponseData>({
        name: '',
        success: false,
        err: undefined,
        message: '',
    });

    function reset() {
        setMessageStatus({
            name: '',
            success: false,
            err: undefined,
            message: '',
        });
    }

    return (
        <Formik
            initialValues={dfContactForm}
            validationSchema={contactSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                // Make Call

                postContactData(data).then((data) => {
                    data.success && resetForm();
                    return setMessageStatus(data);
                });
                setTimeout(() => {
                    setSubmitting(false);
                }, 700);
            }}
        >
            {({ values, isSubmitting, touched, errors }) => (
                <>
                    <motion.div className="form-container">
                        {/* @ts-ignore */}
                        <AnimatePresence exitBeforeEnter>
                            {messageStatus.err ? (
                                <motion.p
                                    key="error-state"
                                    layoutId="error-state"
                                    className="error-state card"
                                    exit={{ y: 100, opacity: 0, scale: 0 }}
                                    initial={{ scale: 0.5, y: 50, opacity: 0.5 }}
                                    animate={{ scale: 1, y: 0, opacity: 1 }}
                                    transition={{ ease: 'easeInOut' }}
                                >
                                    <Button className="reset-button" text="Reset" func={() => reset()} />
                                    <br />
                                    <span>
                                        {messageStatus.message} @{' '}
                                        <a
                                            className="link"
                                            href={`mailto:${contactEmail}?subject=${encodeURIComponent(
                                                values.subject,
                                            )}&body=${encodeURIComponent(values.message)}`}
                                        >
                                            {contactEmail}
                                        </a>
                                    </span>
                                    <br />
                                    <Button
                                        className="copy-button"
                                        text="Copy message to clipboard"
                                        func={() => copyMessage(values.message)}
                                    />
                                </motion.p>
                            ) : messageStatus.success ? (
                                <motion.p
                                    key="success-state"
                                    layoutId="success-state"
                                    className="success-state card"
                                    exit={{ y: 100, opacity: 0, scale: 0 }}
                                    initial={{ y: 50, opacity: 0.5 }}
                                    animate={{ scale: 1, y: 0, opacity: 1 }}
                                    transition={{ ease: 'easeInOut' }}
                                >
                                    {messageStatus.message}
                                </motion.p>
                            ) : (
                                <motion.div
                                    key="form"
                                    layoutId="form"
                                    className="motion-form-wrapper"
                                    exit={{ y: 100, opacity: 0, scale: 0 }}
                                    initial={{ scale: 0.7, y: 50, opacity: 0.7 }}
                                    animate={{ scale: 1, y: 0, opacity: 1 }}
                                    transition={{ ease: 'easeInOut' }}
                                >
                                    <Form>
                                        <FormField type="text" name="name" placeholder="Name" className="name" />

                                        <FormField
                                            type="text"
                                            name="email"
                                            inputMode="email"
                                            placeholder="Email"
                                            className="email"
                                        />

                                        <FormField
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            className="subject"
                                        />

                                        <FormField
                                            type="textarea"
                                            name="message"
                                            placeholder="Your Message"
                                            className="message"
                                        />

                                        {isSubmitting ? <ThreeDotsWave fluid /> : <Button type="submit" text="Send!" />}
                                    </Form>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                </>
            )}
        </Formik>
    );
};

export default ContactForm;
