import React from 'react';
import Products from 'components/shop/Product/Products';
import { Product } from 'components/shop/types';
import { ProductSection } from 'src/types/sanity/schema';
import { PortableText } from 'utils/sanity/sanity';

const ProductsSection: React.FC<ProductSection> = ({ _type, active, body, products, title }) => {
    // TODO Type Queries
    const currentProducts = products as unknown as Product[];

    return (
        <section id={'productSection'}>
            {body && <PortableText blocks={body} />}
            {currentProducts ? <Products products={currentProducts} /> : null}
        </section>
    );
};

export default ProductsSection;
