import { MyLink } from 'components/Layout/Link';
import React from 'react';
import { pluralizeNoun, capitalize } from 'utils/stringFunctions';
import { EmptyProductsListStyles } from '../CartProduct/List';

interface EmptyProductListProps {
    type?: string;
}

const EmptyProductList: React.FC<EmptyProductListProps> = ({ type }) => {
    return (
        <EmptyProductsListStyles>
            <p>
                There Doesn&apos;t seem to be any {type ? pluralizeNoun(capitalize(type)) : 'products of that type'}{' '}
                available. Check back soon!{' '}
            </p>
            <MyLink text="View our Store" hrefProp={`/shop`} />
        </EmptyProductsListStyles>
    );
};

export default EmptyProductList;
