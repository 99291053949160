import ContactUs from 'components/form/ContactUs';
import Newsletter from 'components/form/NewsletterStyles';
import LatestArticles from 'components/journal/LatestArticles';
import HeroBanner from 'components/page-heroes/HeroBanner';
import Divider from 'components/styles/general-elements/Divider';
import { useRouter } from 'next/router';
import React from 'react';
import { Page } from 'src/types/sanity/new-schema';
import Banner from '../Banner';
import Gallery from '../masonry/Gallery';
import CallToAction from './Announcement';
import CollectionSection from './CollectionSection';
import ContentCenterpiece from './ContentCenterpiece';
import FullPage from './FullPageText';
import LongForm from './LongForm';
import ProductsSection from './ProductsSection';
import SectionLinks from './SectionLinks';
import SplitText from './SplitText';
import Testimonial from './Testimonials';
import TextWithImage from './TextWithImage';
interface PageBuilderProps {
    sections?: Page['pageBuilder'];
}

const PageBuilder: React.FC<PageBuilderProps> = ({ sections }) => {
    // if (!sections) return <NoData dataPointName="Page Sections" motionKey="page section" />;
    const router = useRouter();
    return (
        <>
            {sections?.map((section, idx) => {
                switch (section._type) {
                    case 'hero':
                        return <HeroBanner key={section._key} content={section} />;
                    case 'contentCenterpiece':
                        return section.active ? (
                            <ContentCenterpiece
                                key={section._key}
                                content={section.text}
                                title={section.title}
                                id={section.slug?.current}
                            />
                        ) : null;
                    case 'longFormContent':
                        return section.active ? (
                            <LongForm id={section.slug?.current} key={section._key} content={section.body} />
                        ) : null;
                    case 'textWithImage':
                        return section.active ? <TextWithImage key={section._key} {...section} /> : null;
                    case 'banner':
                        return section.active ? <Banner key={section._key} content={section} /> : null;
                    case 'form':
                        return section.form === 'contact' ? (
                            <React.Fragment key={section._key}>
                                {section.active && (
                                    <ContactUs
                                        key={section._key}
                                        id={section.slug?.current}
                                        isHidden={/contact/.test(router.asPath) ? false : true}
                                    />
                                )}
                            </React.Fragment>
                        ) : section.form === 'newsletter' ? (
                            <React.Fragment key={section._key}>
                                {section.active && <Newsletter key={section._key} {...section} />}
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={section._key}>
                                {section.active && <Newsletter key={section._key} {...section} />}
                            </React.Fragment>
                        );
                    case 'callToAction':
                        return section.active ? <CallToAction key={section._key} content={section} /> : null;
                    case 'gallery':
                        return section.active ? <Gallery key={section._key} gallery={section} /> : null;
                    case 'splitText':
                        return section.active ? <SplitText key={section._key} {...section} /> : null;
                    case 'divider':
                        return section.active ? <Divider key={section._key} size={section.size} /> : null;
                    case 'testimonialSlider':
                        return section.active ? <Testimonial key={section._key} {...section} /> : null;
                    case 'latestPosts':
                        return section.active ? <LatestArticles key={section._key} {...section} /> : null;
                    case 'sectionLinks':
                        return section.active ? <SectionLinks key={section._key} {...section} /> : null;
                    case 'productSection':
                        return section.active ? <ProductsSection key={section._key} {...section} /> : null;
                    case 'collectionSection':
                        return section.active ? <CollectionSection key={section._key} {...section} /> : null;
                    case 'fullPageText':
                        return section.active ? <FullPage key={section._key} {...section} /> : null;
                    default:
                        return null;
                }
            })}
        </>
    );
};

export default PageBuilder;
