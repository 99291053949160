import React from 'react';
import styled from 'styled-components';
import { CAContent, ContentProps, ContentTxt, CAHeader, CADivider } from '../../templates/ContentTemplate';
import { sectionPaddingNoBottom, sectionPadding, device } from '../../styles/mixins';
import { SplitText as SplitTextProps } from 'src/types/sanity/schema';
import { PortableText } from 'utils/sanity/sanity';
import Divider from 'components/styles/general-elements/Divider';
import Title from 'components/styles/Title';

interface SplitTextStyleProps {
    column?: boolean;
}

const SplitTextStyles = styled.section<SplitTextStyleProps>`
    .wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: ${({ theme }) => theme.spacing.l} 0;
        --gap: ${({ theme }) => theme.spacing.m};
        @media ${device().medium_small} {
            flex-direction: row;
            align-items: baseline;
        }
    }

    .text,
    .divider {
        width: 100%;
        max-width: ${({ theme }) => theme.typography.maxPWidth};
    }

    @media ${device().medium_small} {
        .divider {
            align-self: center;
            height: 100%;
            /* transform: rotate(90deg); */

            width: auto;
            padding: ${({ theme }) => theme.spacing.xxl} 0;
        }
    }
`;

// interface SplitTextProps extends ContentProps {
//     column?: boolean;
// }

const SplitText: React.FC<SplitTextProps> = ({ left, right, title, slug }) => {
    return (
        <>
            <SplitTextStyles id={slug?.current || ''}>
                {title && <Title as="h3">{title}</Title>}
                <div className="flex-gap wrapper">
                    <div className="text left">
                        <PortableText className="PortableText-container" blocks={left} />
                    </div>
                    <Divider size="s" />
                    <div className="text right">
                        <PortableText className="PortableText-container" blocks={right} />
                    </div>
                </div>
            </SplitTextStyles>
        </>
    );
};

export default SplitText;
