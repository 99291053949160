import React, { useState } from 'react';
import { contactEmail } from '../../core/constants';
import copyMessage from '../../utils/copyToClipBoard';
import Button from '../Layout/Buttons';
import { CADivider } from '../templates/ContentTemplate';
import ContactForm from './ContactForm';
import ContactFormStyles from './ContactFormstyles';

interface ContactFormProps {
    isHidden?: boolean;
    id?: string;
}

const ContactUs: React.FC<ContactFormProps> = ({ isHidden, id }) => {
    const [hiddenFields, setHiddenFields] = useState({ email: true, mob: true, land: true });
    const [isFormHidden, setIsFormHidden] = useState(isHidden);

    function handleFields(name: string): void {
        switch (name) {
            case 'email':
                copyMessage(contactEmail);
                break;

            default:
                break;
        }
        setHiddenFields({ ...hiddenFields, [name]: false });
    }

    if (isFormHidden) {
        return (
            <ContactFormStyles className={isFormHidden ? 'hidden full-bleed' : ''} id={id || 'contact'}>
                <div className="hidden-form">
                    <p className="featured dark">Want to reach out? </p>
                    <Button text="Contact Us" func={() => setIsFormHidden(!isFormHidden)} />
                </div>
            </ContactFormStyles>
        );
    }
    return (
        <ContactFormStyles id="contact">
            <h3>
                Get In Touch <br />
            </h3>
            <div className="form-wrapper">
                <div className="contact-details">
                    <address>
                        <label htmlFor="address">
                            <strong className="lead">Address</strong>
                        </label>
                        <br />
                        Bumblebee Flower Farm,
                        <br />
                        Castledonavan,
                        <br />
                        Drimoleague,
                        <br />
                        Co. Cork
                        <br />
                        Ireland
                    </address>
                    <div className="hidden-details">
                        <strong className="lead">Contact</strong> <br />
                        {hiddenFields.email ? (
                            <Button
                                tooltip="Copy to clipboard"
                                layoutId="hide-email"
                                text="Email"
                                func={() => handleFields('email')}
                            />
                        ) : (
                            <a className="email link" href={`mailto:${contactEmail}?subject=Website Enquiry`}>
                                {contactEmail}
                            </a>
                        )}
                        {hiddenFields.mob ? (
                            <Button layoutId="hide-mob" text="Mobile" func={() => handleFields('mob')} />
                        ) : (
                            <div className="mobile">Mobile: +353 86 082 3318</div>
                        )}
                        {hiddenFields.land ? (
                            <Button layoutId="hide-land" text="Landline" func={() => handleFields('land')} />
                        ) : (
                            <div className="landline">Landline: +353 28 32892</div>
                        )}
                    </div>
                </div>
                <CADivider />
                <ContactForm />
            </div>
        </ContactFormStyles>
    );
};
export default ContactUs;
