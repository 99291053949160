import styled from 'styled-components';
// https://css-tricks.com/seamless-responsive-photo-grid/
export const MasonryWrapper = styled.section`
    margin-right: auto;
    margin-left: auto;
`;
export const Masonry = styled.div`
    /* Prevent vertical gaps */
    line-height: 0;

    -webkit-column-count: 5;
    -webkit-column-gap: 0px;
    -moz-column-count: 5;
    -moz-column-gap: 0px;
    column-count: 5;
    column-gap: 10px;

    @media (max-width: 1200px) {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
    }
    @media (max-width: 1000px) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
    @media (max-width: 800px) {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
    @media (max-width: 400px) {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
`;
export const MasonryItem = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.xs};

    width: 100%;
    height: auto;
`;

// import styled from 'styled-components';

// export const MasonryWrapper = styled.section`
//     margin-right: auto;
//     margin-left: auto;
// `;
// export const Masonry = styled.div`
//     display: grid;
//     gap: ${({ theme }) => theme.spacing.xs};

//     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
//     grid-auto-rows: 240px;

//     /* Medium screens */
//     @media screen and (min-width: 600px) {
//         .card-tall {
//             grid-row: span 2 / auto;
//         }

//         .card-wide {
//             grid-column: span 2 / auto;
//         }

//         .card-large {
//             grid-row: span 2 / auto;
//             grid-column: span 2 / auto;
//         }
//     }
// `;
// export const MasonryItem = styled.div`
//     /* &.card-square { */
//     .MyImage-container {
//         width: 100%;
//         height: 100%;

//         .base-image {
//             width: 100%;
//             height: 100%;
//         }
//         /* max-width: 100%; */
//         /* max-height: 100%; */
//         /* } */
//     }

//     /* margin-bottom: ${({ theme }) => theme.spacing.xs};
//     width: 100%;
//     height: auto; */
// `;
