import { device } from 'components/styles/mixins';
import Title from 'components/styles/Title';
import React from 'react';
import styled from 'styled-components';

const ContentCenterpieceStyles = styled.div`
    width: 100%;
    @media ${device().small} {
        padding-top: ${({ theme }) => theme.spacing.l};
        .ContentCenterpiece-container + & {
            padding-top: 0;
        }
        & + *:not(.ContentCenterpiece-container) {
            padding-top: ${({ theme }) => theme.spacing.l};
        }
    }
    text-align: center;
    h1,
    h2,
    h3,
    h4 {
        padding-bottom: ${({ theme }) => theme.spacing.xs};
    }
    h1,
    h2,
    h3,
    h4,
    p {
        max-width: ${({ theme }) => theme.typography.maxPWidth};
        margin: 0 auto;
    }
`;

interface ContentCenterpieceProps {
    content?: string;
    title?: string;
    id?: string;
}

const ContentCenterpiece: React.FC<ContentCenterpieceProps> = ({ content, title, id }) => {
    return (
        <ContentCenterpieceStyles className="ContentCenterpiece-container" id={id || ''}>
            {title && <Title as="h2">{title}</Title>}
            {content && <p className="featured dark">{content}</p>}
        </ContentCenterpieceStyles>
    );
};

export default ContentCenterpiece;
