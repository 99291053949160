import React from 'react';
import { BlockContent } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { PortableText } from 'utils/sanity/sanity';
import { FullPageText as FullPageTextProps } from 'src/types/sanity/schema';

const FullPageStyles = styled.div``;

const FullPageText: React.FC<FullPageTextProps> = ({ content }) => {
    return (
        <FullPageStyles>
            <PortableText className="PortableText-container" blocks={content} />
        </FullPageStyles>
    );
};

export default FullPageText;
