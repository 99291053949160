import Button from 'components/Layout/Buttons';
import { Column } from 'components/Layout/grid/Column';
import { Row } from 'components/Layout/grid/Row';
import { MyLink } from 'components/Layout/Link';
import MobilePagination from 'components/styles/general-elements/MobilePagination';
import SlidePagination from 'components/styles/general-elements/SlidePagination';
import React from 'react';
import { Hero } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { isBrowser } from 'utils/booleans';
import { nextSanityImage } from 'utils/sanity/sanityImage';
import { modifyAlpha } from 'utils/styleFunctions';
import mixins, { device } from '../styles/mixins';
import { bbTheme } from '../styles/theme';

export interface BannerImageProps {
    readonly imgSrc: string;
    readonly mobileImgSrc?: string;
}

const HeroBannerStyle = styled.section<BannerImageProps>`
    /* min-height: -webkit-fill-available; */
    @media ${device().medium_large} {
        min-height: 100vh;
    }
    position: relative;
    --btn-min-width: 30%;
    grid-column: 1 / -1;

    /* @media ${device().medium_small} {
        min-height: 90rem;
    } */
    /* @media ${device().medium_large} {
        min-height: 50rem;
    } */

    /* @media ${device().medium_small} and ${device(true).medium_large} {
        max-height: 580px;
        min-height: 50vh;
    } */
    > .row-wrapper {
        height: 100%;
    }

    .left-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        order: 1;
        position: relative;
        @media ${device().medium_small} {
            order: 0;
        }
        @media ${device().medium_large} {
            align-items: flex-start;
            text-align: left;
        }

        .cta-section {
            margin: 0 auto;
            width: 90%;
            max-width: 50rem;
        }

        h1 {
            @media ${device(true).medium_small} {
                &.offset-header {
                    position: relative;
                    top: -6rem;
                    background: white;
                    width: fit-content;
                    margin: 0 auto -5rem auto;
                    padding: 1.2rem ${({ theme }) => theme.spacing.m};
                }
            }
        }

        p.featured {
            font-weight: 400;
            @media ${device(true).medium_small} {
                margin: 0 auto ${({ theme }) => theme.spacing.xs} auto;
            }
        }

        div.flex-gap {
            @media ${device().medium_large} {
                justify-content: flex-start;
            }
        }
    }

    &.fullscreen .left-column {
        background: linear-gradient(
                40deg,
                hsl(38deg 100% 99% / 88%) 20.4%,
                hsl(38deg 100% 99% / 42%) 40.83%,
                hsl(38deg 100% 99% / 1%) 62.4%,
                hsl(38deg 100% 99% / 1%) 62.4%
            ),
            url(${({ theme, imgSrc }) => imgSrc}) no-repeat;
        background-size: cover;
        height: 100%;
        min-height: 70rem;
        background-position: top;
        @media ${device(true).small} {
            justify-content: flex-end;
            background: linear-gradient(
                    40deg,
                    hsl(38deg 100% 99% / 88%) 20.4%,
                    hsl(38deg 100% 99% / 42%) 40.83%,
                    hsl(38deg 100% 99% / 1%) 62.4%,
                    hsl(38deg 100% 99% / 1%) 62.4%
                ),
                url(${({ theme, mobileImgSrc, imgSrc }) => mobileImgSrc || imgSrc}) no-repeat center;
            background-size: cover;
        }

        @media ${device().small} {
            min-height: 40rem;
            background-position: center;
            text-align: left;
            align-items: flex-start;
            & .flex-gap {
                justify-content: flex-start;
            }
        }
        &.no-gradient {
            background: url(${({ theme, imgSrc }) => imgSrc}) no-repeat;
            background-size: cover;
            height: 100%;
            min-height: 40rem;
            background-position: center;

            @media ${device(true).small} {
                background: url(${({ theme, mobileImgSrc, imgSrc }) => mobileImgSrc || imgSrc}) no-repeat center;
                background-size: cover;
            }
        }

        .cta-section {
            margin: 15% auto 0 ${({ theme }) => theme.spacing.gutter};

            @media ${device().medium_large} {
                margin: -5% auto 0 ${({ theme }) => theme.spacing.gutter};
            }
        }

        &.home-page-fix-left {
            @media ${device(true).small} {
                .cta-section p {
                    text-shadow: 0.2px 0.2px 0px;
                }
                /* background-position: 82% 0; */
                /* background-attachment: fixed; */
            }
        }
        &.home-page-fix-right {
            @media ${device(true).small} {
                /* background-position: 28% 0; */
                /* background-attachment: fixed; */
            }
        }
    }
    .right-column {
        background: url(${({ theme, imgSrc }) => imgSrc}) no-repeat;
        background-size: cover;
        height: 100%;
        min-height: 25rem;
        background-position: center;

        @media ${device().xxs} {
            min-height: 40rem;
        }
        &.wedding-page-fix {
            background-position: top;
        }
        &.scroll {
            position: relative;
            background-size: contain;
            background-position: top;
            background-size: 100%;

            @media ${device().medium_small} {
                background-position: top;
            }
        }

        & .mask {
            width: 100%;
            position: absolute;
            bottom: 0;
            ${mixins.flex}
            justify-content: space-evenly;

            /* align-items: baseline; */
            height: 7rem;
            background-color: ${({ theme }) => modifyAlpha(theme.colors.backgrounds_light_cream, 0.8)};
            > p {
                font-size: 1rem;
                margin: 0;
            }

            @media ${device().medium_large} {
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: transparent;
                border: 10vw solid ${({ theme }) => theme.colors.backgrounds_light_cream};
                border-width: 10vw 8vw 1vw 8vw;
                box-shadow: inset 0px -8vw 0 0 ${({ theme }) => theme.colors.backgrounds_light_cream};

                button,
                link {
                    border: none;
                }
            }
        }

        .SlidePagination-container {
            position: absolute;
            right: 1.3rem;
            top: 35%;
            z-index: 10;
        }
    }

    &.text-only {
        & .BumblebeeBadge-container {
            position: absolute;
            left: ${({ theme }) => theme.spacing.gutter};
            bottom: ${({ theme }) => theme.spacing.gutter};
        }

        & .left-column {
            max-width: 550px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        [data-icontype='arrow-thin'] {
            position: relative;
            top: 6rem;
        }
    }

    .fullscreen {
    }
`;

export interface HeroProps {
    content: Hero;
    alternateImage?: string;
    classNames?: string[];
    offsetHeader?: boolean;
    scroll?: { pages: { title: string; link?: string }[]; page: number };
}

const HeroBanner: React.FC<HeroProps> = ({ content, classNames = [], offsetHeader, scroll, alternateImage }) => {
    const {
        title,
        image,
        tagline,
        primaryButtonLink,
        primaryButtonText,
        secondaryButtonLink,
        secondaryButtonText,
        textOnly,
        primaryButtonHash,
        secondaryButtonHash,
        fullScreen,
        noGradient,
    } = content;

    const hasPrimaryButton = !!((primaryButtonLink || primaryButtonHash) && primaryButtonText);
    const hasSecondaryButton = !!((secondaryButtonLink || secondaryButtonHash) && secondaryButtonText);

    const nextImage = image ? nextSanityImage(image) : null;

    const mobileImage = image?.mobileImage;
    const nextSanityMobileImage = mobileImage ? nextSanityImage(mobileImage) : null;
    return (
        <HeroBannerStyle
            imgSrc={nextImage?.src || alternateImage || ''}
            mobileImgSrc={nextSanityMobileImage?.src || ''}
            className={`${classNames.join(' ')} ${textOnly ? 'text-only' : ''} ${fullScreen ? 'fullscreen' : ''}`}
        >
            <Row
                breakpoints={
                    textOnly ? undefined : [bbTheme.breakpoints.medium_small, bbTheme.breakpoints.medium_large]
                }
                spacing={[0, 0]}
            >
                <Column
                    spacing={[24, 0]}
                    widths={fullScreen ? [12, 12] : [6, 6]}
                    className={`left-column ${textOnly ? 'text-only' : ''} ${noGradient ? 'no-gradient' : ''} ${
                        title === 'Home' ? 'home-page-fix-left' : ''
                    } `}
                >
                    {' '}
                    <div className={`cta-section fadeIn`}>
                        {scroll && <MobilePagination pages={scroll.pages} currentPage={scroll.page} />}
                        <h1 className={`${offsetHeader ? 'offset-header' : ''}`}>{title}</h1>
                        <p className="featured">{tagline}</p>
                        {(hasPrimaryButton || hasSecondaryButton) && (
                            <div className="flex-gap flex-gap-wrap center mb-xs">
                                {hasSecondaryButton && (
                                    <>
                                        <MyLink
                                            text={secondaryButtonText}
                                            hrefProp={`/${
                                                secondaryButtonLink ? `${secondaryButtonLink}`.replace(/home/i, '') : ''
                                            }${secondaryButtonHash ? '#' + secondaryButtonHash : ''}`}
                                        />
                                    </>
                                )}
                                {hasPrimaryButton && (
                                    <>
                                        <MyLink
                                            text={primaryButtonText}
                                            // func={buttons.primary.action}
                                            hrefProp={`/${
                                                primaryButtonLink ? `${primaryButtonLink}`.replace(/home/i, '') : ''
                                            }${primaryButtonHash ? '#' + primaryButtonHash : ''}`}
                                            btnStyle="primary"
                                        />
                                    </>
                                )}
                            </div>
                        )}

                        {textOnly && !hasPrimaryButton && !hasSecondaryButton && (
                            <Button
                                icon={{ name: 'arrow-thin' }}
                                text=""
                                btnStyle="clear"
                                title="Scroll Down"
                                func={() => {
                                    if (!isBrowser) return;
                                    window.scrollBy({
                                        top: window.innerHeight - window.innerHeight / 3,
                                        left: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                            />
                        )}
                        {/* <BumblebeeBadge /> */}
                    </div>
                </Column>
                {!textOnly && !fullScreen && (
                    <Column
                        fadeIn
                        spacing={[0, 0]}
                        widths={[6, 6]}
                        className={`${scroll ? 'scroll' : ''} right-column ${
                            /wedding/i.test(title) ? 'wedding-page-fix' : ''
                        }`}
                    >
                        {scroll && (
                            <div className="mask">
                                {scroll.pages[scroll.page].link ? (
                                    <MyLink
                                        hrefProp={scroll.pages[scroll.page].link || ''}
                                        text={scroll.pages[scroll.page].title}
                                        btnStyle="clear"
                                    />
                                ) : (
                                    <p className="caption-text micro">{scroll.pages[scroll.page].title}</p>
                                )}
                            </div>
                        )}
                        {scroll && (
                            <>
                                <SlidePagination pages={scroll.pages} currentPage={scroll.page} />
                            </>
                        )}
                    </Column>
                )}
            </Row>
        </HeroBannerStyle>
    );
};

export default HeroBanner;
