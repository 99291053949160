import React from 'react';
import styled from 'styled-components';
import { clampN } from 'utils/numberFunctions';
import mixins from '../mixins';
import { PaginationProps } from './SlidePagination';

const MobilePaginationStyles = styled.div`
    ${mixins.flex}

    .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.light_grey_soft_lines};
        margin: 5px;
    }

    .active {
        background-color: ${({ theme }) => theme.colors.black};
    }
`;

const MobilePagination: React.FC<PaginationProps> = ({ pages, currentPage }) => {
    return (
        <MobilePaginationStyles className="MobilePagination-container show-for-medium">
            {pages.map((page, idx) => (
                <span
                    title={page.title}
                    key={`page-${idx}`}
                    className={`dot ${idx + 1 === clampN(currentPage, 1, pages.length) ? 'active' : ''}`}
                />
            ))}
        </MobilePaginationStyles>
    );
};

export default MobilePagination;
