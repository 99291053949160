import DOMPurify from 'isomorphic-dompurify';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

function transform(node, index) {
    if (node.type === 'tag' && node.name === 'p') {
        node.attribs.style = '';
        node.name = 'span';
        return convertNodeToElement(node, index, transform);
    }
    // return null to block certain elements
    // don't allow <span> elements
    if (node.type === 'tag' && node.name === 'span') {
        node.attribs.style = '';
        node.name = 'span';
        return convertNodeToElement(node, index, transform);
    }

    // Transform <ul> into <ol>
    // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
    if (node.type === 'tag' && node.name === 'ul') {
        return convertNodeToElement(node, index, transform);
    }

    // return an <i> element for every <b>
    // a key must be included for all elements

    // all links must open in a new window
    if (node.type === 'tag' && node.name === 'a') {
        node.attribs.target = '_blank';
        node.attribs.rel = 'noopener noreffer';
        return convertNodeToElement(node, index, transform);
    }
    return convertNodeToElement(node, index, transform);
}

function transformWPEditor(node, index) {
    if (node.type === 'tag' && node.name === 'div') {
        node.attribs.style = '';

        return convertNodeToElement(node, index, transformWPEditor);
    }
    // return null to block certain elements
    // don't allow <span> elements
    // if (node.type === 'tag' && node.name === 'span') {
    //     node.attribs.style = '';
    //     node.name = 'span';
    //     return convertNodeToElement(node, index, transformWPEditor);
    // }

    // Transform <ul> into <ol>
    // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
    // if (node.type === 'tag' && node.name === 'ul') {
    //     return convertNodeToElement(node, index, transformWPEditor);
    // }

    // all links must open in a new window
    // if (node.type === 'tag' && node.name === 'a') {
    //     node.attribs.target = '_blank';
    //     node.attribs.rel = 'noopener noreffer';
    //     return convertNodeToElement(node, index, transformWPEditor);
    // }
    return convertNodeToElement(node, index, transformWPEditor);
}
const options = {
    decodeEntities: true,
    transform,
};

function parseHtml(htmlString: string) {
    return ReactHtmlParser(DOMPurify.sanitize(htmlString), options);
}

const wpEditor = {
    decodeEntities: true,
    transformWPEditor,
};

export function parseWPHtml(htmlString: string) {
    return ReactHtmlParser(DOMPurify.sanitize(htmlString), wpEditor);
}

export default parseHtml;
