import Title from 'components/styles/Title';
import React from 'react';
import { Form } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { banner } from '../styles/mixins';
import NewsLetterForm from './NewsLetterForm';

export const NewsLetterStyles = styled.section`
    ${banner}
    background: ${({ theme }) => theme.colors.gradients.soft_pink};
    min-height: 33rem;
    padding: ${({ theme }) => theme.spacing.gutter};
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    h3 {
        margin: 0 3rem 0 0;
        @media only screen and (max-width: 900px) {
            margin: 0;
            br {
                display: none;
            }
        }
    }
    .form-wrapper {
        max-width: 1100px;
        position: relative;
        z-index: 2;
        margin: 0 auto;
        /* background-color: rgba(247, 247, 247, 0.36); */
        align-items: center;
        width: 100%;

        @media only screen and (max-width: 900px) {
            flex-flow: column;
        }
        @media only screen and (max-width: 600px) {
            margin: 0;
        }
    }

    .tagline {
        flex: 0 0 45%;
        width: 100%;
    }

    .newsletter-form {
        flex: 0 0 45%;
    }
`;

const Newsletter: React.FC<Form> = ({ slug, title, tagline }) => {
    return (
        <NewsLetterStyles id={slug?.current || 'newsletter'} className="full-bleed">
            <Title as="h3">{title || <>Join our Newsletter</>}</Title>
            <div className={`form-wrapper flex-gap flex-gap-wrap ${tagline ? 'space-between' : 'center'}`}>
                {tagline && <p className="tagline featured">{tagline}</p>}
                <NewsLetterForm />
            </div>
        </NewsLetterStyles>
    );
};

export default Newsletter;
