import { ContactForm } from '../../src/types/globalTypes';
import { ContactResponseData } from '../../pages/api/contact';
import { dev } from 'core/constants';
import { devLog } from 'utils/logs';

async function postContactData(values: ContactForm): Promise<ContactResponseData> {
    if (values.email === dev.email && process.env.NODE_ENV === 'development') {
        devLog('Dev Email submitted - sending unconfirmed 200');
        return {
            name: 'Developer',
            success: true,
            message: `Thanks ${values.name}! We'll reply to you at ${values.email} as soon as we can.`,
            err: undefined,
        };
    }
    const res = await fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' },
    });

    const json: ContactResponseData = await res.json();

    return json;
}

export default postContactData;
