import Title from 'components/styles/Title';
import { defaultMetaTags } from 'core/constants';
import React, { useRef } from 'react';
import { Article, LatestPosts } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { fyShuffle } from 'utils/arrayFunctions';
import { MyLink } from '../Layout/Link';
import { device, sectionMargin } from '../styles/mixins';
import ArticleMeta from './ArticleMeta';

const LatestArticlesStyles = styled.section`
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding-top: 5rem;
    display: flex;
    flex-direction: column;

    @media ${device().medium_large} {
        gap: ${({ theme }) => theme.spacing.xs};
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: min-content 1fr;
    }
    .topnav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        grid-column: 1 / span 3;
        grid-row: 1 / span 1;

        @media ${device(true).medium} {
            margin-bottom: ${({ theme }) => theme.spacing.xs};
        }
    }

    .image {
        height: 100%;
        max-height: 35rem;
        min-height: 35rem;

        @media only screen and (max-width: 900px) {
            height: 100%;
            max-height: 45rem;
        }
    }
    .i1 {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;

        @media ${device(true).medium} {
            margin-bottom: ${({ theme }) => theme.spacing.xs};
        }
    }

    .i2 {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;

        @media ${device(true).medium} {
            margin-bottom: ${({ theme }) => theme.spacing.xs};
        }
    }

    .i3 {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;

        @media ${device(true).medium} {
            margin-bottom: ${({ theme }) => theme.spacing.xs};
        }
    }

    .excerpt {
        padding: ${({ theme }) => theme.spacing.xs};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

interface Props {
    articles: Article[];
}

const LatestArticles: React.FC<LatestPosts> = ({ categories, title }) => {
    const cats = categories as unknown as ArticleCatWithArticles[];

    const allCatArticles = useRef(
        // @ts-expect-error Not too sure why... //TODO
        fyShuffle(cats.reduce((acc, curr) => acc.concat(curr.articles), [])).filter((el, idx) => idx < 3),
    );
    type ArticleCatWithArticles = {
        _id: string;
        articles: Article[];
        title: string;
    };

    return (
        <LatestArticlesStyles id="latest">
            <div className="topnav">
                <Title noMargin as="h2">
                    Recent Happenings
                </Title>
                <MyLink hrefProp="/journal" text="All the articles" />
                {/* {loading ? <ThreeDotsWave fluid /> : <Button text="Load more" func={loadMore} disabled={loading} />} */}
            </div>
            {allCatArticles &&
                allCatArticles.current.map((article, idx) => (
                    <React.Fragment key={article._id}>
                        <ArticleMeta
                            article={article}
                            idx={idx + 1}
                            className={`${idx === 0 ? 'featured' : ''} i${idx + 1}`}
                            onIndex
                        />
                        {/* {article.excerpt && (
                        <>
                            <p className={`${idx === 0 ? '' : ''}  excerpt ex${idx + 1}`}>
                                <span>“{article.excerpt}”</span>
                                <MyLink
                                    hrefProp={`/journal/${article?.slug}`}
                                    text="Read More"
                                    className="mt-xs currentColor"
                                    btnStyle="clear"
                                />
                            </p>
                        </>
                    )} */}
                    </React.Fragment>
                ))}
        </LatestArticlesStyles>
    );
};

export default LatestArticles;
