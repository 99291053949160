import { Column } from 'components/Layout/grid/Column';
import { Row } from 'components/Layout/grid/Row';
import { device } from 'components/styles/mixins';
import { bbTheme } from 'components/styles/theme';
import Title from 'components/styles/Title';
import React from 'react';
import { Article } from 'src/types/sanity/schema';
import styled from 'styled-components';
import ArticleMeta from './ArticleMeta';

export const RelatedStoriesStyles = styled.section`
    max-width: calc(100vw - 7.8vw);
    margin: 0 auto;
    margin-top: ${({ theme }) => theme.spacing.xxl};
    & > .title {
        text-align: center;
        margin: ${({ theme }) => theme.spacing.s};
    }

    @media ${device().medium} and ${device(true).xl} {
        .column-4 {
            display: none;
        }
    }
`;
interface RelatedStoriesProps {
    articles: Article[];
}

const RelatedStories: React.FC<RelatedStoriesProps> = ({ articles }) => {
    const { small, medium, xl } = bbTheme.breakpoints;

    return (
        <RelatedStoriesStyles className="RelatedStories-container">
            <Title as="h3">Related Stories</Title>
            <Row breakpoints={[small, medium, xl]}>
                {articles.map((article, idx) => {
                    return idx < 3 ? (
                        <Column key={article._id} widths={[6, 4, 4]}>
                            {article && <ArticleMeta article={article} asRelated />}
                        </Column>
                    ) : null;
                })}
            </Row>
        </RelatedStoriesStyles>
    );
};

export default RelatedStories;
