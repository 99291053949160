import { Column } from 'components/Layout/grid/Column';
import { bbTheme } from 'components/styles/theme';
import React, { useContext, useEffect } from 'react';
import { Q_ALL_PRODUCTS } from 'src/queries/sanity';
import useSWR from 'swr';
import { getClient } from 'utils/sanity/sanity';
import { CartContext, UserContext } from '../../../state/context/global';
import { logProductIndexImpression } from '../../../utils/analytics';
import { isBrowser } from '../../../utils/booleans';
import { convertRems } from '../../../utils/stringFunctions';
import ThreeDotsWave from '../../animations/loading/ThreeDotsLoader';
import ATCModal from '../CartProduct/ATCModal';
import { Product } from '../types';
import EmptyProductList from './EmptyProductList';
import ProductDescription from './ProductDescription';
import ProductMeta from './ProductMeta';
import { ProductIndexStyles } from './ProductStyles';
import dayjs from 'dayjs';
import { isDev, isStaging } from 'pages/_app';
// ReactModal.setAppElement('#app');

export interface ProductsProps {
    type?: 'cut-flowers' | 'gift-card' | 'event' | 'online-course' | 'wedding-flowers' | 'dried-flowers' | 'plants';
    preview?: boolean;
    products?: Product[];
    isExpandedView?: boolean;
}

const Products: React.FC<ProductsProps> = ({ type, preview, products, isExpandedView }) => {
    // const { data, loading } = useQuery<ProductsData, ProductsDataVariables>(ALL_PRODUCTS, {
    //     variables: { preferredContentType: ImageContentType.JPG, type: type || '' },
    //     skip: !!products,
    //     ssr: true,
    // });

    const { data: swrData, error } = useSWR<Product[]>(Q_ALL_PRODUCTS, (query) => getClient().fetch(query));

    const cart = useContext(CartContext);
    const user = useContext(UserContext);

    const currentProducts = products || swrData;

    useEffect(() => {
        if (currentProducts && !preview && isBrowser && user?.cookieStatus === 'accept') {
            logProductIndexImpression(currentProducts, window.location.pathname, 'viewProducts');
        }
        cart?.toggleCartModal(undefined);
    }, []);

    if (!currentProducts || !cart) {
        return <ThreeDotsWave />;
    }

    if (!currentProducts.length) return <EmptyProductList type={type} />;

    const { medium_small, medium_large, large } = bbTheme.breakpoints;

    return (
        <>
            <ProductIndexStyles
                breakpoints={[medium_small, medium_large, large]}
                maxColumnCount={2}
                className="no-margin"
            >
                {currentProducts
                    .filter((product) => (isDev || isStaging ? true : !/TEST/gi.test(product?.store.title)))
                    // FIXME - Use Function
                    .filter((product) => (product.expiryDate ? dayjs(product.expiryDate).isAfter(dayjs()) : true))
                    .sort((a, b) => (b.priority || 0) - (a.priority || 0))
                    .map((product, idx) => {
                        return (
                            <Column
                                key={product.store.id}
                                widths={isExpandedView ? [12, 12, 12] : [6, 4, 4]}
                                // offsets={[1, 1, 1]}
                                spacing={[convertRems(bbTheme.spacing.m), convertRems(bbTheme.spacing.m)]}
                                fadeIn
                                className={`no-padding ${isExpandedView ? 'flex-gap collection-product' : ''}`}
                            >
                                <ProductMeta
                                    metaData={{
                                        tagline: product.tagline || '',
                                        reviews: {
                                            count: Math.round(Math.random() * 20),
                                            score: 5,
                                        },
                                    }}
                                    product={product}
                                />
                                {/* This needs a better design to be effective */}
                                {isExpandedView && <ProductDescription product={product} isSingle />}
                            </Column>
                        );
                    })}
            </ProductIndexStyles>

            {cart.modalComponent && <ATCModal cartProduct={cart.modalComponent} />}
        </>
    );
};

export default Products;

// <ReactModal
//     isOpen={!!router.query.title}
//     onAfterOpen={afterOpenModal}
//     onRequestClose={
//         () =>
//             router.push(`/shop`, undefined, {
//                 scroll: false,
//             })
//         // router.push(`${currentTopLevelPath ? currentTopLevelPath[0] : '/shop'}`, undefined, {
//         //     scroll: false,
//         // })
//     }
//     // style={customStyles}
//     contentLabel={currentProduct.node.title}
//     // className="product-modal"
//     // overlayClassName="product-modal-overlay"
//     style={{ content: { inset: '7% 5%', top: '10%' } }}
// >

//     <ProductDetailStyles className="full-bleed quick-view">
//         {currentProduct.node && (
//             <>
//                 {/* {() =>
//                         logSingleProductClick(
//                             currentProduct.node,
//                             undefined,
//                             currentProduct.node.handle,
//                             'viewPDP',
//                         )
//                     } */}
//                 <SingleProduct
//                     product={currentProduct.node}
//                     isSingle
//                     isRouteModal
//                     initialOptions={queryStringToOptions(
//                         currentProduct.node.variants.edges[0].node.selectedOptions,
//                         currentProduct.node,
//                     )}
//                 />
//             </>
//         )}
//     </ProductDetailStyles>
//     <div className="flex-gap buttons mt-xs">
//         <MyLink
//             hrefProp={`${router.asPath}`}
//             text="Full View"
//             func={() => {
//                 logSingleProductClick(
//                     currentProduct.node,
//                     undefined,
//                     currentProduct.node.handle,
//                     'viewPDP',
//                 );
//             }}
//         />
//         <Button
//             text="Close"
//             func={
//                 () =>
//                     router.push(`/shop`, undefined, {
//                         scroll: false,
//                     })
//                 // router.push(`${currentTopLevelPath ? currentTopLevelPath[0] : '/shop'}`, undefined, {
//                 //     scroll: false,
//                 // })
//             }
//         />
//     </div>
// </ReactModal>
