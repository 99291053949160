import React from 'react';
import { BlockContent } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { PortableText } from 'utils/sanity/sanity';

const LongFormStyles = styled.div``;
interface LongFormProps {
    content: BlockContent;
    id?: string;
}

const LongForm: React.FC<LongFormProps> = ({ content, id }) => {
    return (
        <LongFormStyles className="LongForm-container breakout-grid" id={id || ''}>
            <PortableText className="PortableText-container" blocks={content} />
        </LongFormStyles>
    );
};

export default LongForm;
