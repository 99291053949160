import { device } from 'components/styles/mixins';
import { bbTheme } from 'components/styles/theme';
import React from 'react';
import { TextWithImage as TextWithImageProps } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { PortableText } from 'utils/sanity/sanity';
import { getSanityRefId, nextSanityImage } from 'utils/sanity/sanityImage';
import { convertRems } from 'utils/stringFunctions';
import { Column } from '../grid/Column';
import { Row } from '../grid/Row';
import MyImage from '../MyImage';

const TextWithImageStyles = styled(Row)`
    /* & * {
        border: 1px solid red;
    } */
    @media ${device(true).small} {
        grid-column: 2 / -1;
    }

    .column-wrapper {
        display: flex;
        align-items: center;
        /* max-height: 50rem; */
    }
    .column-1 {
        padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.gutter};
        & > div {
            max-width: ${({ theme }) => theme.typography.maxPWidth};
        }
    }

    .column-2 {
        position: relative;
        min-height: 45rem;
        padding-right: 0;
        padding-left: 0;

        & > div {
            position: absolute;
        }

        @media ${device().medium_large} {
            & > div {
                position: relative;
            }
        }

        @media ${device().medium_small} {
            &.left {
                order: -1;
                left: -${({ theme }) => theme.spacing.m};
            }
            &.right {
                /* right: -${({ theme }) => theme.spacing.gutter}; */
                padding-right: 0;
            }
        }
    }
`;

const TextWithImage: React.FC<TextWithImageProps> = ({ body, image, slug }) => {
    const { small, medium_small, medium_large } = bbTheme.breakpoints;
    const { s, m, l } = bbTheme.spacing;
    // FIXME
    const hotfixHasID = getSanityRefId(image);
    if (!hotfixHasID) return null;
    const nextImage = nextSanityImage(image);

    return (
        // <div ref={ref} className="wrapper">
        <TextWithImageStyles
            className={`TextWithImage-container no-margin full-bleed`}
            breakpoints={[small, medium_small, medium_large]}
            spacing={[convertRems(s), convertRems(s)]}
            id={slug?.current || ''}
        >
            <Column
                widths={[10, 6, 6]}
                className="no-padding"
                // spacing={[convertRems(l), convertRems(s)]}
                // fadeIn
            >
                {<PortableText className="PortableText-container" blocks={body} />}
            </Column>
            <Column fadeIn widths={[12, 6, 6]} offsets={[0, 0, 0]} className={`${image?.imageOrientation}`}>
                {nextImage && image && (
                    <MyImage
                        clickZoom={image.lightbox}
                        images={[nextImage.src]}
                        loader="sanity"
                        nextImageProps={{
                            src: nextImage.src,
                            layout: 'fill',
                            objectFit: image.imageFit,
                            objectPosition: image.imagePosition,
                            alt: image.alt,
                        }}
                    />
                )}
            </Column>
        </TextWithImageStyles>
        // </div>
    );
};

export default TextWithImage;
